<template>
  <df-modal
    persistent
    width="640px"
    :title="$t('SowingView.spraying_efficacy_modal.title')"
    @close="closeEfficacyModal"
  >
    <template #card-content>
      <div class="df-flex-xl df-flex-col spraying-efficacy-modal">
        <div class="df-flex-m df-flex-col">
          <h1>{{ $t('SowingView.spraying_efficacy_modal.efficacies') }}</h1>
          <div v-if="loading" class="df-flex-m df-flex-col">
            <v-skeleton-loader
              v-for="(_, index) in efficacies.length || 5"
              :key="index"
              height="28px"
              type="image"
              width="auto"
            />
          </div>
          <efficacy-chart
            v-else
            data-id="efficacy-chart"
            colorful="true"
            :efficacies="translatedEfficacies"
          />
        </div>
        <v-divider />
        <div class="df-flex-l df-flex-col">
          <h1>{{ $t('SowingView.spraying_efficacy_modal.products') }}</h1>
          <div data-id="efficacy-products" class="df-flex-sm df-flex-col">
            <span v-for="(product, index) in products" :key="index">
              {{ product }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </df-modal>
</template>

<script>
import DfModal from '@/lib/Modal/DfModal.vue'
import sowingViewService from '@/modules/sowingView/services/sowingViewService.js'
import '@/components/Efficacies/EfficacyChart.js'

export default {
  name: 'SprayingEfficacyModal',

  components: {
    DfModal,
  },

  props: {
    products: {
      required: true,
      type: Array,
    },
    sprayingId: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      efficacies: [],
      loading: false,
    }
  },

  async created() {
    this.loading = true
    const params = {
      spraying_id: this.sprayingId,
    }
    try {
      const { data } = await sowingViewService.getSprayingEfficacies(params)
      this.efficacies = data
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  },

  computed: {
    translatedEfficacies() {
      let translatedEfficacies = [...this.efficacies]
      translatedEfficacies = translatedEfficacies.map((efficacy) => {
        return {
          disease_name: this.$t(`diseases.${efficacy.disease_name}`),
          efficacy: efficacy.efficacy.toFixed(2),
        }
      })
      return JSON.stringify(translatedEfficacies)
    },
  },

  methods: {
    closeEfficacyModal() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.spraying-efficacy-modal {
  h1 {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  span {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
